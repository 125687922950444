
import React, {  useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom'
import Title from './Title';
import QuantityBtn from './QuantityBtn'
// CAUTION: React is not {} from react!!

export default function ProductDetail() {

    let params = useParams()
    let [productDetail,setProductDetail] = useState(null)

    useEffect(()=>{
        // 1.no second param , component every render
        // 2. Dependency Array is [], just render on init
        // 3. Dependency Array is variable, render init and variable change

        // 2022.02.28 KC: just config https chun.young for CORS
        fetch('https://chun.young/demoapi/react-basic-product.json')
        .then(response => response.json())
        .then(data => {
            //setProductList(data)
            let productInfo = data.find((element) => {
                return element.id === parseInt(params.id)
            })
            setProductDetail(productInfo)
        })

        //console.log(productList) - not need

    },[])   // Dependency list for 2nd param

    // NOTE: fetch json, so productDetail may not exist on first pace
    return (
        <div>
            {
                productDetail &&
                <div>
                    <Title mainTitle={productDetail.name + 'ProductDetail'} />

                    <img src={process.env.PUBLIC_URL+'/img/'+productDetail.image}
                    alt={productDetail.name} width="400" />

                    <p>Product Name: {productDetail.name}</p>
                    <p>Price: {productDetail.price}</p>
                    <p>Description: {productDetail.description}</p>

                    <QuantityBtn productInfo={productDetail}/>
                </div>
            }

            <Link to="/">Back to Product List</Link>
        </div>
    )
}
