import React, { useContext } from 'react'
import { useQuantity, useState } from "react" // React Hook 16.8
import { CartContext } from './CartContext'

export default function QuantityBtn({productInfo}) {

    const {cartItems, setCartItems} = useContext(CartContext)

    // Check Cart have such product
    let productIndexInCart = cartItems.findIndex((element)=>{
        // NOTES: 3 equals!!!
        return element.id === productInfo.id
        //return element.id
    })

    console.log("productIndexInCart "+productIndexInCart)

    // findIndex
    // if in cart => cart index number
    // if not in cart => -1

    let [numInCart,setNumInCart] = useState(
        (productIndexInCart===-1) ? 0 : cartItems[productIndexInCart].quantity
    )

    const handleAdd = ()=>{

       
        if (productIndexInCart===-1)
        {
            
            // not in cart, add to cartItems
            setCartItems([
                {
                    id: productInfo.id,
                    name: productInfo.name,
                    image: productInfo.image,
                    price: productInfo.price,
                    description: productInfo.description,
                    quantity: 1                 
                },
                ...cartItems]
            )
            
                   
        }
        else
        {
            // in cart, add quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity++;
            setCartItems(newCartArray)
        }

        setNumInCart(numInCart+1)
    }

    const handleSubtract = ()=>{

        if (cartItems[productIndexInCart].quantity===1)
        {  
            // remove object
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart,1)
            setCartItems(newCartArray)
        }
        else
        {
            // quantity minus 1
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--;
            setCartItems(newCartArray)
        }

        setNumInCart(numInCart-1)
    }

    return (
        <div>
            {
                (numInCart === 0) ?
                    
          
                    <div onClick={handleAdd}> Add to {productInfo.name} Cart</div> :
                    <div>
                        <span onClick={handleSubtract}>-</span>
                        {numInCart} #
                        <span onClick={handleAdd}>+</span>
                    </div>

            }
        </div>
    )
}
