import {Link} from 'react-router-dom'
import styles from './ProductList.module.css'
import {useState, useEffect} from "react" // React Hook 16.8
import React, { Component } from 'react'
import logo from './react-logo.png';
import Title from './Title';
import QuantityBtn from './QuantityBtn';

export default function ProductList() {

    // let is small letter

    /*
    let productList = [
        { "id": 1, "name": "Apple", "price": 5, "image": "apple.jpg", "description": "Fresh Apple 50 gram" },
        { "id": 2, "name": "Orange", "price": 3, "image": "orange.jpg", "description": "Fresh Orange 50 gram" },
        { "id": 3, "name": "Mange", "price": 4, "image": "mango.jpg", "description": "Fresh Mango 500 gram" },
        { "id": 4, "name": "Watermelon", "price": 20, "image": "watermelon.jpg", "description": "Fresh Watermelon 2KG " },
        { "id": 5, "name": "Blueberry", "price": 10, "image": "blueberry.jpg", "description": "Fresh Blueberry 50 gram" },
        { "id": 6, "name": "White Carrot", "price": 5, "image": "white-carrot.jpg", "description": "Fresh White Carrot 1 kg" }
    ]
    */

    let [productList, setProductList] = useState([])
    let [input, setInput] = useState('')
 

    useEffect(()=>{
        // 1.no second param , component every render
        // 2. Dependency Array is [], just render on init
        // 3. Dependency Array is variable, render init and variable change

        // 2022.02.28 KC: just config https chun.young for CORS
        fetch('https://chun.young/demoapi/react-basic-product.json')
        .then(response => response.json())
        .then(data => setProductList(data))

        console.log(productList)
    },[])   // Dependency list for 2nd param


    useEffect(()=>{
        if(input.length>4)
            console.log('length is enough')
        else 
            console.log('length is short')
            

    },[input])   

    //.then(data => console.log(data))

    return (
        // React Fragment
        <>
            <input type="text" onChange={e=>setInput(e.target.value)}/>
            <Title mainTitle="React Entrance Fruit Shop" /> 

            <div>
                {

                    productList.map(product => (
                        <React.Fragment className={styles.productBorder} key={product.id}>
                            {product.name}<br/>
                            {product.price}<br/>
                            <Link to={'/product/'+product.id}>
                                <img src={process.env.PUBLIC_URL+'/img/'+product.image} />
                            </Link>
                            {product.description}<br/>
                            <QuantityBtn productInfo={product} />
                        </React.Fragment>
                    ))

                }

            </div>
        </>
    )
}

