import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import Checkout from './Checkout';
import ProductList from './ProductList';
import ProductDetail from './ProductDetail';
import {CartContext} from './CartContext'
import {useState} from 'react';


function App() {

    const [cartItems, setCartItems] = useState([])

    return (
        <BrowserRouter>

            <CartContext.Provider value={{cartItems,setCartItems}}>


                <Link to="/">Home</Link>
                <Link to="/checkout">Checkout</Link>
                <Routes>
                    <Route path="/" element={<ProductList />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/product" element={<ProductDetail />} >
                        <Route path=":id" element={<ProductDetail />} />
                    </Route>
                    <Route path="*" element={<p>404 Not Found</p>} />
                </Routes>

            </CartContext.Provider>


        </BrowserRouter>

    );
}

export default App;
