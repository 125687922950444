import React, { useContext } from 'react'
import Title from './Title'
import {Link} from 'react-router-dom'
import QuantityBtn from './QuantityBtn'
import { CartContext } from './CartContext'


export default function Checkout() {

    let {cartItems} = useContext(CartContext)
    let cartEmpty = cartItems.length<=0 ? true : false
    let grandTotal = cartItems.reduce((total, product)=>{
        return total += product.price*product.quantity
    },0)
    const freeShippingPrice = 99

    return (

        <div>
            
            <Title mainTitle="Your Shopping Cart" />

            {
                cartEmpty &&
                <div>
                    Shopping Cart is Empty<br/>
                    <Link to="/">Go Product List to see see</Link>
                </div>
            }  


            {
                !cartEmpty &&
                <div>
                    <div id="cartSection">
                        {
                            /* Product List */
                            cartItems.map(product=>(
                                <div key={product.id}>
                                    <img src={process.env.PUBLIC_URL+'/img/'+product.image} />
                                    {product.name}
                                    {product.description}
                                    {product.price}
                                    Buy Quantity {product.quantity}
                                    <QuantityBtn productInfo={product}/>

                                </div>
                          ))
                        }
                    </div>
                    <div id="checkOutSection">

                        {/* Grand Price Total */}
                        <div>Products Grand Total </div>
                        <div>{grandTotal} dollars </div>

                        {
                            /* Free Delivery */
                            grandTotal >= freeShippingPrice ?
                            <div>We Free Delivery</div>:
                            <div>Upper then ${freeShippingPrice} Free Delivery<br/>
                            Still have ${freeShippingPrice-grandTotal}</div>

                        }
                    </div>
                </div>
            }   

        </div>
    )
}
